export const Business = Object.freeze({
    name: "Strange Music Hollywood",
    address: "6801 Hollywood Blvd STE 200",
    city: "Hollywood",
    state: "CA",
    zip: "90028",
    phoneNumber: "(323) 498-5255",
    email: "info@strangemusichollywood.com",
    url: "https://www.strangemusichollywood.com",
    facebook: "https://www.facebook.com/strangemusichollywood/",
    twitter: "https://twitter.com/StrangeMusicHW",
    instagram: "https://www.instagram.com/strangemusichollywood/",
    youtube: "https://www.youtube.com/channel/UC8DzuMJd2WeR9A6sUmwjRQg"
})